import { createMuiTheme, createStyles, WithStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { isIos, isIphone } from './hooks/mobileDetect'
//TODO move light and dark theme here
const defaultTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
})

export type DefaultTheme = typeof defaultTheme
export const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    container: {
      marginTop: spacing.unit * 4,
      marginBottom: spacing.unit * 4,
      marginLeft: spacing.unit * 4,
      marginRight: spacing.unit * 4,
    },
    hiddenContainer: {
      overflow: 'hidden',
      height: '0px',
    },
    amountInput: {
      fontSize: '1.5rem',
    },
    fullHeight: isIos
      ? {
          minHeight: '-webkit-fill-available',
        }
      : {
          height: 'calc(100vh - 48px)',
        },
    stepWithError: {
      color: palette.error.main,
    },
    errorIcon: {
      color: palette.error.main,
      verticalAlign: 'sub',
      marginRight: 5,
      marginBottom: '-2px',
    },
    legal: {
      fontSize: '0.75rem',
      color: palette.grey[500],
      lineHeight: 1.1,
      fontWeight: 400,
    },
    message: {
      fontSize: '0.85rem',
      color: palette.grey[600],
      lineHeight: 1.2,
      fontWeight: 300,
    },
    errorTitle: {
      marginBottom: '0.4rem',
      fontSize: '0.9rem',
      color: palette.grey[800],
      fontWeight: 500,
    },
    button: {
      width: '100%',
    },
    overviewTable: {
      '& thead': {
        '& tr': {
          height: spacing.unit * 4,
        },
      },
      '& svg': {
        fontSize: 18,
        marginBottom: '-4px',
      },
      '& .material-icons': {
        fontSize: 18,
        marginBottom: '-4px',
      },
    },
    noBorderRow: {
      borderBottom: 'none',
      '& td': {
        borderBottom: 'none',
      },
    },
    nativeTableRow: isIphone
      ? {}
      : {
          borderBottom: 'none',
          '& td': {
            borderBottom: 'none',
          },
        },
    collapsedTableRow: {
      height: '0px',
      overflow: 'hidden',
      '& td': {
        borderBottom: 'none',
      },
    },
    overviewSubTable: {
      paddingBottom: 0,
      paddingTop: 0,
    },
    nowrap: {
      whiteSpace: 'nowrap',
    },
    androidInfoIcon: {
      marginRight: spacing.unit * 2,
      '& span': {
        marginRight: spacing.unit * 2,
      },
    },
    // economic profile
    longLabel: {
      fontSize: '0.8rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      right: 22,
      bottom: 0,
    },
    longLabelShrink: {
      right: 'unset',
    },
    textLink: {
      color: palette.primary.main,
      cursor: 'pointer',
    },
    textRight: {
      textAlign: 'right',
    },
    walletOwnerMessage: {
      margin: '10px 0 24px 0',
    },
  })

export type ThemedProps = WithStyles<typeof styles>
