import { createContext } from 'react'

export interface IWidgetContext {
  mobile: boolean
}

const WidgetContext = createContext<IWidgetContext>({
  mobile: false,
})

export default WidgetContext
