import { ApolloClient } from 'apollo-client'
import {
  defaultDataIdFromObject,
  InMemoryCache,
  IntrospectionFragmentMatcher,
  IntrospectionResultData,
} from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import schema from './schema.json'
import { GRAPHQL_ENDPOINT } from '../../utils'

const introspectionQueryResultData: IntrospectionResultData = (
  schema as unknown as {
    data: IntrospectionResultData
  }
).data

export const getApolloClient = () => {
  const cache = new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({ introspectionQueryResultData }),
    dataIdFromObject: (object) => {
      switch (object.__typename) {
        case 'Client':
          return object.__typename
        default:
          return defaultDataIdFromObject(object)
      }
    },
  })

  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          )
        }
        if (networkError) console.log(`[Network error]: ${networkError}`)
      }),
      new HttpLink({
        uri: GRAPHQL_ENDPOINT,
        credentials: 'include',
      }),
    ]),
    cache,
  })
}

export const isTypename =
  <T extends string>(typename: T) =>
  <N extends { __typename?: string }>(node: N): node is Extract<N, { __typename: T }> => {
    return node.__typename === typename
  }

export const guardType = <N extends { __typename?: string }, T extends string>(
  k: T,
  n?: N
): Extract<N, { __typename: T }> | undefined => {
  if (n && n.__typename === k) {
    return n as Extract<N, { __typename: T }>
  } else {
    return undefined
  }
}
