import React, { lazy, Suspense } from 'react'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './App.css'
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { styles } from './components/common/theme'
import { getApolloClient } from './services/graphql/utils'
import { REST_ENDPOINT } from './utils'

const EconomicProfileWidget = lazy(() =>
  import('./components/economic_profile/EconomicProfileWidget')
)
const WithdrawalWidget = lazy(() => import('./components/withdraw_flow/WithdrawalWidget'))

const overrides = {
  MuiButton: {
    root: {
      width: '100%',
    },
  },
  MuiGrid: {
    'direction-xs-column': {
      flexWrap: 'nowrap',
    },
  },
}

export const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#007aff',
      dark: '#0055B2',
      light: '#3394FF',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#ff9f00',
      secondary: 'rgb(95, 33, 32)',
      background: 'rgb(253, 237, 237)',
    },
    warning: {
      main: 'rgb(102, 60, 0)',
      background: 'rgb(255, 244, 229)',
    },
    info: {
      main: 'rgb(1, 67, 97)',
      background: 'rgb(229, 246, 253)',
    },
    success: {
      main: 'rgb(30, 70, 32)',
      background: 'rgb(237, 247, 237)',
    },
    secondary: {
      main: '#999999',
      dark: '#000000',
      light: '#FFFFFF',
      contrastText: '#FFFFFF',
    },
    lightgreen: {
      color: '#E5F9F3',
      textColor: '#27b28a',
    },
    lightred: {
      color: '#FFEDEE',
    },
    lightyellow: {
      color: '#FF9F0024',
    },
  },
  typography: {
    fontFamily: 'system-ui,-apple-system, sans-serif',
    useNextVariants: true,
  },
  overrides,
})

export const darkTheme = createMuiTheme({
  boxShadowStyle: '0 1px 4px 0 rgba(0,0,0,0.04), 0 4px 16px 0 rgba(0,0,0,0.08)',
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#419CFF',
      dark: '#0055B2',
      light: '#3394FF',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#999999',
      dark: '#FFFFFF',
      light: '#FFFFFF',
    },
    error: {
      main: '#EF2D34',
      dark: '#FF7C81',
      light: '#FFD4D6',
      secondary: 'rgb(244, 199, 199)',
      background: 'rgb(22, 11, 11)',
    },
    warning: {
      main: 'rgb(255, 226, 183)',
      background: 'rgb(25, 18, 7)',
    },
    info: {
      main: 'rgb(184, 231, 251)',
      background: 'rgb(7, 19, 24)',
    },
    success: {
      main: 'rgb(204, 232, 205)',
      background: 'rgb(12, 19, 13)',
    },
    background: {
      default: '#0C0C0C',
      paper: '#0C0C0C',
    },
    green: {
      color: '#57EBC0',
      textColor: '#FFFFFF',
    },
    lightgreen: {
      color: '#00614580',
      textColor: '#57EBC0',
    },
    lightblue: {
      color: '#00499980',
      textColor: '#FFFFFF',
    },
    yellow: {
      color: '#FF9F00',
    },
    lightyellow: {
      color: '#FF9F0024',
    },
    red: {
      color: '#FF7C81',
      textColor: '#FFFFFF',
    },
    lightred: {
      color: '#87101550',
    },
    grey: {
      color: 'rgba(255,255,255,0.75)',
      300: '#0C0C0C',
      400: '#0C0C0C',
    },
    lightgrey: {
      color: 'rgba(255,255,255,0.12)',
    },
    extralightgrey: {
      color: 'rgba(255,255,255,0.05)',
    },
    extralightgreyBackground: {
      color: 'rgba(255,255,255,0.12)',
    },
    black: {
      color: '#FFFFFF',
    },
    purple: {
      color: '#E65CE6',
    },
    lightpurple: {
      color: '#7A1F7A30',
    },
    cyan: {
      color: '#00D3FF',
    },
    lightcyan: {
      color: '#00CCFF24',
    },
    lightorange: {
      color: '#D7890824',
    },
    lightgreyBackgroundSolid: {
      color: '#333333',
    },
    gold: {
      color: '#E6C200',
    },
    harlequin: {
      color: '#3cd10b',
    },
    lightharlequin: {
      color: '#e8ffe030',
    },
  },
  overrides,
})

const MainContainer = withStyles(styles, { withTheme: true })(({ children, classes }) => {
  return <div className={classes.container}>{children}</div>
})

const App = () => {
  const apolloClient = getApolloClient()

  const urlParams = new URLSearchParams(window.location.search)
  const theme = urlParams.get('theme') === 'dark' ? darkTheme : lightTheme
  document.body.classList.add(urlParams.get('theme') === 'dark' ? 'dark' : 'light')

  return (
    <ApolloProvider client={apolloClient}>
      <MuiThemeProvider theme={theme}>
        <MainContainer>
          <CssBaseline />
          <BrowserRouter>
            <Suspense fallback={<div />}>
              <Switch>
                <Route path="/withdrawal_flow">
                  <WithdrawalWidget
                    account={Number(urlParams.get('account'))}
                    amount={Number(urlParams.get('amount'))}
                    mobile
                    restEndpointUrl={REST_ENDPOINT}
                  />
                </Route>
                <Route path="/economic_profile">
                  <EconomicProfileWidget mobile />
                </Route>
              </Switch>
            </Suspense>
          </BrowserRouter>
        </MainContainer>
      </MuiThemeProvider>
    </ApolloProvider>
  )
}

export default App
