const baseHostName = window.location.hostname.split('.').slice(-2).join('.')
const scheme = process.env.NODE_ENV === 'production' ? 'https' : 'http'

export const REST_ENDPOINT =
  process.env.REACT_APP_REST_ENDPOINT_URL !== undefined
    ? process.env.REACT_APP_REST_ENDPOINT_URL
    : `${scheme}://web-api-generic.${baseHostName}`

export const GRAPHQL_ENDPOINT = `${REST_ENDPOINT}/graphql`

export const TERMS_ENDPOINT = {
  web: {
    links: {
      bdSwiss: {
        termsAndConditions: 'https://eu.bdswiss.com/general-terms-and-conditions',
      },
      bdSwissVanuatu: {
        termsAndConditions: 'https://global.bdswiss.com/general-terms-and-conditions',
      },
      bdSwissMauritius: {
        termsAndConditions: 'https://mau.bdswiss.com/general-terms-and-conditions/',
      },
      bdSwissSouthAfrica: {
        termsAndConditions: 'https://za.bdswiss.com/general-terms-and-conditions',
      },
      bdSwissComoros: {
        termsAndConditions: 'https://km.bdswiss.com/general-terms-and-conditions',
      },
    },
  },
}
