import { omit } from 'lodash'

declare const window: Window

interface IAndroidAppInterface {
  postMessage: (message: string, payload: string) => void
}

interface IAppInterface {
  postMessage: (message: any, targetOrigin?: string, transfer?: Transferable[]) => void
}

interface Window {
  webkit?: {
    messageHandlers: {
      appInterface: IAppInterface
    }
  }
  appInterface?: IAndroidAppInterface
}

export interface IBasicAppMessage {
  type: 'updateToolbar' | 'loading' | 'success' | 'error' | 'warning' | 'logout'
}

interface ILoadingAppMessage extends IBasicAppMessage {
  type: 'loading'
  isLoading: boolean
}

interface IErrorAppMessage extends IBasicAppMessage {
  type: 'error'
  description: string
}

interface IWarningAppMessage extends IBasicAppMessage {
  type: 'warning'
  description: string
}

interface ISuccessAppMessage extends IBasicAppMessage {
  type: 'success'
  description?: string
}

interface ILogoutAppMessage extends IBasicAppMessage {
  type: 'logout'
  description?: string
  title?: string
}

interface IUpdateToolbarAppMessage extends IBasicAppMessage {
  type: 'updateToolbar'
  primaryButton: {
    label: string
    disabled: boolean
  }
}

export type IAppMessage =
  | IUpdateToolbarAppMessage
  | IErrorAppMessage
  | IWarningAppMessage
  | ISuccessAppMessage
  | ILoadingAppMessage
  | ILogoutAppMessage

export function postAppMessage(msg: IAppMessage) {
  if (process.env.NODE_ENV === 'development') {
    console.debug(msg)
  }
  if ((window as any).webkit?.messageHandlers?.appInterface) {
    window.webkit?.messageHandlers.appInterface.postMessage(msg)
  } else {
    window.appInterface?.postMessage(msg.type, JSON.stringify(omit(msg, 'type')))
  }
}

export type PostAppMessage = typeof postAppMessage
