import { useContext, useMemo } from 'react'
import WidgetContext from '../contexts/WidgetContext'

export const isIphone = navigator.platform === 'iPhone'
export const isIpod = navigator.platform === 'iPod'
// the platform on iPad on iOS 13+ is `MacIntel`, the Mac also is `MacIntel` so we need to check for `touch` events
export const isIpad = ['iPad', 'MacIntel'].includes(navigator.platform) && 'ontouchend' in document
export const isIos = isIphone || isIpad || isIpod
export const isBDSwissApp = navigator.userAgent.includes('BDSwiss')
export const hideButtons = isIos && isBDSwissApp

export default function useMobileDetect(): {
  mobile: boolean
} {
  const { mobile } = useContext(WidgetContext)
  return useMemo(() => {
    return {
      mobile,
    }
  }, [mobile])
}
